import { ref } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios';
export const useFiltersSortingAndPagination = (data) => {
 
  if(!data){
    data = {};
  }

  const filters = ref({
    q: data.q || '',
    organization: data.organization || null,
    page: data.page? parseInt(data.page): 1,
    limit: data.limit ? parseInt(data.limit) : 10,
 
  })



  return {
    // Filter
    filters,
    // Sort

  }
}

export const useUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalSamples = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalSamples,
  }
}

export const useRemoteData = () => {
  const samples = ref([])
  const fetchSamples = (args) => {
    return new Promise((resolve, reject) => {
      console.log(args)
      axios
        .get('/samples', {params: args})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })


  }
  const deleteSamples = (args) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/samples/delete', args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  return {
    samples,
    fetchSamples,
    deleteSamples
  }
}
