<template>
    <div>
        <div >
            
                
                <div  :key="index" v-for="(v, index) in confItem.value">
                    <div class="tool-container" style="margin-top: 10px;">
                        <div >
                            <label>{{ confItem.title }}</label>
                            <v-select 
                                @input="setSelected($event, v, tools)"
                                label="text" :reduce="option => option.value" v-model="v.templateId" :options="tools" />
                            <label for="basic-password">Alias name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="v.alias"
                            />
                            <label>Description</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="v.description"
                            />
                        </div>
                        <div v-if="v.parameters" style="margin-top:10px;" class="tool-container">
                            
                            <div  :key="k" v-for="(input, k) in v.parameters">
                                <div><label style="font-weight: bold;">{{ input.name }}</label></div>
                                <label for="basic-password">Alias</label>
                                <input
                                    type="text"


                                    class="form-control"
                                    v-model="input.alias"
                                />
                                <label>Description</label>
                                <input
                                    type="text"


                                    class="form-control"
                                    v-model="input.description"
                                />
                                <hr/>
                            </div>
                        </div>
                    </div>
               
            </div>

        </div>
        <b-button
            style="width: 100%; margin-top: 10px;"
            @click="addNewItm()"
            variant="gradient-primary"
          >
            +
          </b-button>
    </div>
</template>
<script>
import vSelect from 'vue-select';
import axios from '@axios'
import {


  BButton,

} from "bootstrap-vue";
export default {
    props: ["confItem", "organization"],
    components: {vSelect, BButton},
    data(){
        return {
            tools: [],
        }
    },
    methods: {
        addNewItm(){
            this.confItem.value.push(JSON.parse(JSON.stringify(this.confItem.default)))
        },
        setSelected(value, targetTool, options){
            const option = options.find((x) => x.value == value);
            targetTool.parameters = option.inputs;
            targetTool.description = option.description;
            targetTool.alias = option.description;
            targetTool.name = option.text;
        }
    },
    created(){
              axios
                .get(`/templates`, {
                  params: {
                    templateTypes: "PUBLIC_SUB_TEMPLATE",
                    selectedFields: "id name inputs outputs documentation",
                    limit: 100,
                    onlyTemplates: 1,
                    organizations: this.organization,
                  },
                }).then((templates) => {
                    this.tools = templates.data.templates.results.map(x=> {
                        x.inputs.forEach(k => {
                            k.alias = k.name;
                            k.description = k.label
                            if(k.configsMap){
                                k.description += ". " + k.configsMap.documentation
                                delete k.configsMap;
                            }

                        })
                        return {
                            text: x.name,
                            value: x.id,
                            description: x.documentation["description"],
                            inputs: x.inputs
                        };
                     }
                    );

                })
    }
}
</script>
<style scoped>
    .tool-container {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 15px;
        border-radius: 3px;
    }
    hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    }
</style>