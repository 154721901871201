<template>
  <div>
      <div v-if="confItem.type == 'text'">
        <label for="basic-password">{{ confItem.title }}</label>
        <div role="group" class="input-group">
          <input
            type="text"
            :placeholder="confItem.title"
            :disabled="confItem.disabled"

            class="form-control"
            v-model="confItem.value"
          />
        </div>
      </div>
      <div v-if="confItem.type == 'json'">
        <JsonEditorVue
          v-model="confItem.value"
          v-bind="{/* local props & attrs */}"
        />
      </div>
      <div v-if="confItem.type == 'tools'">
        <ToolsConfig :organization="organization" :confItem="confItem" />
      </div>
      <div v-if="confItem.type == 'configs'">
        <label for="basic-password">{{ confItem.title }}</label>
        <b-row>
          <b-col md="6" :key="conf.text" v-for="conf in confItem.value">
            <b-form-input 
              type="text"
              :placeholder="conf.text"

              class="form-control"
              v-model="conf.value"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="confItem.type == 'jscode'">
        <label for="basic-password">{{ confItem.title }}</label>
        <js-code :code.sync="confItem.value"></js-code>
        <b-button @click="testCode" variant="primary" size="sm" style="margin-top: 10px;">
          Test
          <b-spinner v-if="testCodeLoading" small></b-spinner>
        </b-button>
    <!-- danger -->
    <b-alert style="margin-top:10px;" v-if="testCodeError"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Error
      </h4>
      <div class="alert-body">
        <span>{{testCodeError}}</span>
      </div>
    </b-alert>
      </div>
      <div v-if="confItem.type == 'textarea'">
        <label for="basic-password">{{ confItem.title }}</label>
        <div role="group" class="input-group">
          <b-form-textarea
            class="contilt-textarea"
            id="textarea-auto-height"
            debounce="500"
            :state="confItem.error ? false: true"

            :placeholder="confItem.title"
            rows="3"
            max-rows="8"
            v-model="confItem.value"
          />
          <b-form-invalid-feedback v-if="confItem.error">
            {{confItem.error}}
          </b-form-invalid-feedback>
          
        </div>
      </div>

      <div v-if="confItem.type == 'float'">
        <label for="basic-password">{{ confItem.title }}</label>
        <div role="group" class="input-group">
          <input
            type="number"
            placeholder=""
            class="form-control"
            v-model.number="confItem.value"
          />
        </div>
      </div>

      <div v-if="confItem.type == 'boolean'">
        <b-form-checkbox
          v-model="confItem.value"
          name="check-button"
          switch
          inline
        >
          {{ confItem.title }}
        </b-form-checkbox>
      </div>
      <div v-if="confItem.type == 'select'">
        <label>{{ confItem.title }}</label>
        <v-select @input="setSelected($event, confItem)" 
              label="text" @open="onSelectOpen(confItem)" :reduce="option => option.value" v-model="confItem.value" :options="confItem.options" />
      </div>
      <div v-if="confItem.type == 'multiselect'">
        <label>{{ confItem.title }}</label>
        <v-select label="text" @open="onSelectOpen(confItem)" :reduce="option => option.value" v-model="confItem.value" :options="confItem.options" multiple />
      </div>
      <div v-if="confItem.type == 'tags'">
        <label>{{ confItem.title }}</label>
        <b-form-tags
          v-model="confItem.value"
          :placeholder="confItem.title"
          class="mb-2"
        />
      </div>
      <div v-if="confItem.type == 'itemlist'">
        <div
          v-for="(innerItm, innerIndex) in confItem.items"
          style="position: relative"
          :key="'item oi'+ innerIndex"
          class="inner-card"
        >
          <div
            v-if="confItem.disableRemoveDefault && innerIndex>0"
            style="position: absolute; top: 0px; right: 0px; overflow: visible"
          >
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon"
              @click="removeConfigItem(confItem._original, innerIndex)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
          <TemplateConfigFactory @connect-to-inputs="$emit('connect-to-inputs', $event)" :inputs="inputs" :innerClass="'inner-card-item'" :configItems="innerItm" /> 

        </div>
        <div v-if="confItem.addMoreText" style="width: 100%; padding-top: 10px">
          <b-button
            style="width: 100%"
            @click="addNewItm(confItem._original)"
            variant="gradient-primary"
          >
            {{ confItem.addMoreText }}
          </b-button>
        </div>
      </div>
    </div>

</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import {

  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
  BButton,
  BFormTags,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormInput,
  BAlert
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from 'vue-select';
import axios from "@axios";
import JsCode from "./JsCode.vue"
import Tribute from "tributejs";
import ToolsConfig from './ToolsConfig.vue';
export default {
  name: "TemplateConfigItemsInputs",
  data(){
    return {
      testCodeLoading: false,
      testCodeError: null,
    }
  },
  mounted(){
    let _this = this;
    this.$nextTick(() => {
      if(this.inputs && this.inputs.length > 0 && document.querySelectorAll(".contilt-textarea").length){
      var tribute = new Tribute({
        trigger: '{{',
        selectTemplate: function(item) {
          if (typeof item === "undefined") return null;
          setTimeout(() => {
            _this.$emit('connect-to-inputs')
          }, 900);
          return "{{" + item.original.value + "}}";
        },
        values: this.inputs.map(x=> {
          return {
            key: x,
            value: x
          }
        })
      });
      tribute.attach(document.querySelectorAll(".contilt-textarea"));
      }

    })
  },
  components: {
    BButton,
    BFormTags,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BFormValidFeedback,
    BFormInvalidFeedback,
    vSelect,
    JsCode,
    BAlert,
    BSpinner,
      BRow,
  BCol,
  BFormInput,
  ToolsConfig,
  JsonEditorVue,
    TemplateConfigFactory: () => import('./TemplateConfigFactory.vue'),
  },
  directives: {
    Ripple,
  },
  props: ["configItems", "confItem", "inputs"],
  computed: {
    organization() {
            return this.$route.query.organization;
    },
    templateId() {
      return this.$route.params.templateId;
    }
  },
  methods: {
    testCode(){
      let _this = this;
      _this.testCodeLoading = true;
      _this.testCodeError = null;
      this.$emit('test-code', function(err){
        if(err){
          _this.testCodeError = err && err.response && err.response.data && err.response.data.error ? err.response.data.error.message : err.message;
        }
        _this.testCodeLoading =false
      })
    },
    addNewItm(itemListObject) {
      itemListObject.items.push(
        JSON.parse(JSON.stringify(itemListObject.defaultTemplate))
      );
    },
    removeConfigItem(itemListObject, index) {
      itemListObject.items.splice(index, 1);
    },
    setSelected(value, configItem) {
      if(!configItem.loading){
        return
      }
      if (!value) {
        this.$emit("set-inputs-outputs", [], []);
        return;
      }
      const options = configItem.options;
      const option = options.find((x) => x.value == value);
      if (option && option.inputs && option.outputs) {
        this.$emit("set-inputs-outputs", option.inputs, option.outputs);
      }
      else if(option && option.inputs){
        this.$emit("set-inputs-outputs", option.inputs, []);
      }
      else if(option && option.outputs){
        this.$emit("set-inputs-outputs", [], option.outputs);
      }
    },
    
    onSelectOpen(configItem){
      if(configItem.loading){
        if(configItem.loading == "getForTemplates"){
          axios
            .get(`/templates`, {
              params: {
                sourceReference: this.templateId,
                templateTypes: "FOR_TEMPLATE",
                selectedFields: "id name",
                onlyTemplates: 1,
                organizations: this.organization
              }
            }).then(res => {
              configItem.options = res.data.templates.map(x=>{
                return {
                  text: x.name,
                  value: x.id
                }
              })
            })
        }
        if(configItem.loading == "getEntities"){
          axios
            .get(`/entities`, {
              params: {
                selectedFields: "id name",
                limit: 100,
                organizations: this.organization
              }
            }).then(res => {
            
              configItem.options = res.data.results.map(x=>{
                return {
                  text: x.name,
                  value: x.id
                }
              })
            })
        }
        if(configItem.loading == "getTemplateTypes"){
          axios
            .get(`/templateTypes`, {
              params: {
                selectedFields: "id name fields",
                limit: 100,
                organizations: this.organization
              }
            }).then(res => {
              configItem.options = res.data.results.filter(x=>x.fields).map(x=>{
                return {
                  text: x.name,
                  value: x.id,
                  inputs: x.fields.map(y=>{return {name: y.name}}),
                }
              })
            })
        }
        if(configItem.loading == "getVisualTemplates"){
          const templateType = this.configItems.find(x=>x.loading == "getTemplateTypes");

          axios
            .get(`/visualTemplates`, {
              params: {
                selectedFields: "id name templateTypeId",
                populate: "templateTypeId$fields",
                templateTypeId: templateType ? templateType.value: null,
                limit: 100,
                organizations: this.organization
              }
            }).then(res => {
              configItem.options = res.data.results.map(x=>{
                return {
                  text: x.name,
                  value: x.id,
                  inputs: x.templateTypeId.fields.map(y=>{return {name: y.name}}),
                }
              })
            })
        }
      } 
    }
  }
};
</script>

<style>
.inner-card {
  background-color: rgba(0, 0, 0, 0.035);
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
}

.inner-card-item {
  margin: 5px;
}
.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  overflow: auto;
  display: block;
  z-index: 999999;
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #efefef;
}
.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}
.tribute-container li.highlight {
  background: #ddd;
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
.b-form-tags-input::placeholder {
  color: #ccccd4;
}
</style>
