<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalTemplateTypes }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="showNewTemplateTypeModal()"
                >New Template Type</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="templateType in curretTemplateTypes"
        :key="templateType.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link class="text-body">
              {{ templateType.name }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ templateType.organization }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ templateType.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="$refs['clone'].showModal(templateType)"
          >
            <span>Clone</span>
          </b-button>
          <b-button
          
            variant="relief-primary"
            class="btn-wishlist"
            @click="$refs['assign'].showModal([templateType])"
          >
            <span>Assign</span>
          </b-button>
          <b-button
            variant="relief-warning"
            class="btn-wishlist"
            @click="showNewTemplateTypeModal(templateType)"
          >
            <span>Edit</span>
          </b-button>
          <b-button
            variant="relief-danger"
            class="btn-cart"
            @click="deleteSelectedTemplateTypes([templateType])"
          >
            <span>Delete</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalTemplateTypes"
            :per-page="filters.limit"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <portal to="content-renderer-sidebar-detached-left">
      <div>
        <template-types-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
      </div>
    </portal>
    <b-modal
      id="modal-prevent-closing"
      ref="new-template-type-modal"
      :title="createOrUpdate + ' Template Type'"
      :ok-title="createOrUpdate"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetNewTemplateTypeModal"
      @ok="newTemplateTypeOk"
    >
      <form ref="form" @submit.stop.prevent="newTemplateTypeSubmit">
        <b-form-group
          :state="newTemplateTypeState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="newTemplateType.name"
            :state="newTemplateTypeState"
            required
          />
        </b-form-group>
        <b-form-group :state="newTemplateTypeState" label="Description">
          <b-form-textarea
            v-model="newTemplateType.description"
            :state="newTemplateTypeState"
            required
          />
        </b-form-group>
        <b-form-group>
           <template-config-items v-if="newTemplateType.fieldsConfig"
                  
                  @add-new-itm="addNewItm"
                  :configItems="newTemplateType.fieldsConfig"
             
                ></template-config-items>
        </b-form-group>
             
      </form>
    </b-modal>

    <assign-modal
      ref="assign"
      @on-assign="assignHandleSubmit"
      :organizations="filterOptions.organizations"
    ></assign-modal>
    <clone-modal ref="clone" @on-clone="cloneHandleSubmit"></clone-modal>

    <div id="contilt-temp"></div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BFormGroup,
  BFormTags,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import {
  useFiltersSortingAndPagination,
  useUi,
  useRemoteData,
} from "./useTemplateType";
import organizationStoreModule from "../../organization/organizationStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import TemplateTypesLeftFilterSidebar from "./TemplateTypesLeftFilterSidebar.vue";
import axios from "@axios";
import AssignModal from "../../../modals/AssignModal";
import CloneModal from "../../../modals/CloneModal";
import router from "@/router";
import TemplateConfigItems from "../template-editor/TemplateConfigItems.vue";
export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BFormGroup,
    TemplateTypesLeftFilterSidebar,
    BFormTags,
    BFormTextarea,
    AssignModal,
    CloneModal,
    TemplateConfigItems
  },
  data() {
    return {
      createOrUpdate: "Create",
      newTemplateType: {
        id: null,
        name: "",
        description: "",
        fieldsConfig: null
      },
      newTemplateTypeState: null,
      filterOptions: {
        organizations: [],
      },
      toAssign: null,
      assignOrganizationsState: null,
    };
  },
  computed: {
    curretTemplateTypes() {
      return this.templateTypes;
    },
    type() {
        return this.$route.query.type;
    },
  },
  methods: {
    assignHandleSubmit(data) {
     
      axios
        .post("/templateTypes/assign", {
          templateTypes: data.toAssign,
          destinationOrganization: data.destinationOrganization,
        })
        .then(() => this.fetchTheTemplateTypes())
        .catch((error) => console.log(error));
    },

    cloneHandleSubmit(data) {
      axios
        .post(`/templateTypes/clone/${data.id}`, { name: data.name })
        .then(() => this.fetchTheTemplateTypes())
        .catch((error) => console.log(error));
    },
   addNewItm(itemListObject) {
      itemListObject.items.push(
        JSON.parse(JSON.stringify(itemListObject.defaultTemplate))
      );
    },
    showNewTemplateTypeModal(templateType) {
  
 
      this.newTemplateType.fieldsConfig = [
        {
          type: "itemlist",
          name: "items",
          title: "Object Fields",
          addMoreText: "+",
          defaultTemplate: [
            {
              type: "text",
              name: "variableName",
              title: "Field Name",
              value: "",
              conditional: "varType!=splitter",
            },
            {
              type: "select",
              name: "varType",
              title: "Field Type",
              value: "string",
              options: [
                { value: "string", text: "String" },
                { value: "image", text: "Image" }
            
              ],
            }
      
          
          ],
          items: [
            [
              {
                type: "text",
                name: "variableName",
                title: "Field Name",
                value: "",
                conditional: "varType!=splitter",
              },
              {
                type: "select",
                name: "varType",
                title: "Field Type",
                value: "string",
                options: [
                  { value: "string", text: "String" },
                  { value: "image", text: "Image" }
                ],
              },
         
              
            ],
          ],
        },
      ];

    
      if(templateType){
        this.createOrUpdate = "Update"
        this.newTemplateType.id = templateType.id;
        this.newTemplateType.name = templateType.name;
        this.newTemplateType.description = templateType.description;

        this.newTemplateType.fieldsConfig[0].items = [];
        templateType.fields.forEach(x => {
          const item = JSON.parse(JSON.stringify(this.newTemplateType.fieldsConfig[0].defaultTemplate));
          item[0].value = x.name;
          item[1].value = x.type;
          this.newTemplateType.fieldsConfig[0].items.push(item);
        })

      }else {
        this.createOrUpdate = "Create";
        this.newTemplateType.id = null;
      }
      this.$refs["new-template-type-modal"].show();
    },
    checkNewTemplateTypeFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.newTemplateTypeState = valid;
      return valid;
    },
    resetNewTemplateTypeModal() {
      this.newTemplateType = {
        name: "",
        description: "",
        fieldsConfig: null
      };
      this.newTemplateTypeState = null;
    },
    newTemplateTypeOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.newTemplateTypeSubmit();
      this.resetNewTemplateTypeModal();
    },
    newTemplateTypeSubmit() {
      // Exit when the form isn't valid
      if (!this.checkNewTemplateTypeFormValidity()) {
        return;
      }
      const data = JSON.parse(JSON.stringify(this.newTemplateType));

      data.fields = data.fieldsConfig[0].items.map(x=> {
        return {
          name: x[0].value,
          type: x[1].value
        }
      });
      delete data.fieldsConfig;
      data.type = this.type;
      

       if(this.createOrUpdate == "Update"){
        axios
          .patch(`/templateTypes/${data.id}`, data)
          .then(() => this.fetchTheTemplateTypes())
          .catch((error) => console.log(error));
      }
      else {
        axios
          .post("/templateTypes", data)
          .then(() => {
            this.fetchTheTemplateTypes();
          })
          .catch((error) => console.log(error));
      }
  

      this.$nextTick(() => {
        this.$refs["new-template-type-modal"].toggle("#toggle-btn");
      });
    },

    deleteSelectedTemplateTypes(toDelete) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to delete the selected template types [${toDelete.map(
            (x) => x.name
          )}]?`,
          {
            title: "Delete Template Types",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteTemplateTypes(toDelete.map((x) => x.id)).then((res) => {
              this.fetchTheTemplateTypes();
              console.log(res);
            });
          }
        });
    },
  },
  created() {
    let _this = this;
    store
      .dispatch("app-organization/fetchOrganizations", {selectedFields: "id name", limit: 1000})
      .then((response) => {
        const organizations = response.data.results.map((x) => {
          return {
            id: x.id,
            value: x.name,
          };
        });

        _this.filterOptions.organizations = organizations;
        if (!_this.filters.organization) {
          _this.filters.organization = organizations[0].id;
        } else {
          this.fetchTheTemplateTypes();
        }
      });
  },
  setup() {
    function startLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "contents";
    }
    function stopLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "none";
    }

    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );

    const { filters } = useFiltersSortingAndPagination(
      router.currentRoute.query
    );

    const { itemView, itemViewOptions, totalTemplateTypes } = useUi();

    const { templateTypes, fetchTemplateTypes, deleteTemplateTypes } =
      useRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const fetchTheTemplateTypes = () => {
      startLoading();
      const orgs = [];
      if (filters.value.organization) {
        orgs.push(filters.value.organization);
      }
      const query = {
        q: filters.value.q,
        organizations: orgs.join(","),
        type: router.currentRoute.query.type,
        page: filters.value.page,
        limit: filters.value.limit,
      };

      router.replace({
        params: router.currentRoute.params,
        query: { ...router.currentRoute.query, ...filters.value, ...query },
      });

      fetchTemplateTypes(query).then((response) => {
        stopLoading();
        templateTypes.value = response.data.results;
        totalTemplateTypes.value = response.data.totalResults;
      });
    };

    watch(
      [filters],
      () => {
        fetchTheTemplateTypes();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,

      // useShopUi
      itemView,
      itemViewOptions,
      totalTemplateTypes,
      deleteTemplateTypes,
      // useShopRemoteData
      templateTypes,
      fetchTheTemplateTypes,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
#contilt-temp {
  display: none;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
