<template> 
  <div>
    <b-button
     
      :variant="pin ? 'flat-success': 'flat-secondary'"
      class="btn-icon rounded-circle pin-btn"
      @click="$emit('pin')"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-fill" viewBox="0 0 16 16">
      <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z"/>
    </svg>
    </b-button>
    <TemplateConfigFactory 
      @test-code="testCode"
      :inputs="inputs"
      :innerClass="'mb-1'"
      @connect-to-inputs="$emit('connect-to-inputs', $event)"
        @set-inputs-outputs="setInputsOutputs"
    :configItems="selectedNodeConfigItems" />
  </div>
</template>

<script>
import axios from '@axios';


import TemplateConfigFactory from "./TemplateConfigFactory.vue";
import { BButton } from 'bootstrap-vue'
export default {

  components: {
    TemplateConfigFactory,
    BButton
  },
  props: ["configItems", "pin", "inputs"],
  
  computed: {
    selectedNodeConfigItems() {
      return this.filterHiddenConfigs(this.configItems);
    },
  },
  methods: {
    testCode(callback){
      const code = this.selectedNodeConfigItems.find(x =>x.name == "code");
      const inputs = this.selectedNodeConfigItems.find(x =>x.name == "inputs");
      const outputs = this.selectedNodeConfigItems.find(x =>x.name == "outputs");
      if(code && inputs && outputs){
        const codeFunction = code.value;
        const inputsObject = {};
        inputs.items.forEach(y => {
          inputsObject[y[0].value] = y[1].value
        })
        const outputsObject = {};
        outputs.items.forEach(y => {
          outputsObject[y[0].value] = y[1].value
        })
        axios.post(`/pipeline/runJsFunction`, {inputs: inputsObject,  outputs: outputsObject, code: codeFunction}).then((res) => {
          outputs.items.forEach(y => {
            const varName = y[0].value;
            if(res.data[varName]){
              y[1].value = res.data[varName]
            }
            
          })
          if(callback){
            callback();
          }
        }).catch(err => {
            if(callback){
              callback(err);
            }
        })
      }
    },
     setInputsOutputs(inputs, outputs){
        this.$emit('set-inputs-outputs', inputs, outputs)

     },
    filterHiddenConfigs(configList) {
      let self = this;
      let res = [];
      let kvm = {};
      configList.forEach(function (itm) {
        // some weird bug
        if (!itm) {
          return;
        }

        if (itm && "name" in itm && "value" in itm) {
          kvm[itm["name"]] = itm["value"];
        }
      });

      configList.forEach(function (itm) {
        // some weird bug
        if (!itm) {
          return;
        }

        let shouldinclude = true;
        if ("conditional" in itm) {
          let conditions = itm["conditional"];
          let conditionList = [conditions];
          let operator = "";

          if (conditions.includes("||")) {
            conditionList = conditions.split("||");
            operator = "or";
          } else {
            if (conditions.includes("&&")) {
              conditionList = conditions.split("&&");
              operator = "and";
            }
          }

          shouldinclude = operator == "and" ? true : false;

          conditionList.forEach(function (cond) {
            if (cond.includes("!=")) {
              let condsplit = cond.split("!=");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if(attrVal == "null"){
                attrVal = null
              }
              if(attrVal == "undefined"){
                attrVal = undefined
              }
              if(attrVal == "false"){
                attrVal = false
              }
              if(attrVal == "true"){
                attrVal = true
              }
              if (operator == "and") {
                shouldinclude = shouldinclude && !(kvm[attrName] == attrVal);
              } else {
                shouldinclude = shouldinclude || !(kvm[attrName] == attrVal);
              }
              return;
            }

            if (cond.includes("==")) {
              let condsplit = cond.split("==");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] == attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] == attrVal;
              }
              return;
            }

            if (cond.includes(">")) {
              let condsplit = cond.split(">");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] > attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] > attrVal;
              }
              return;
            }

            if (cond.includes("<")) {
              let condsplit = cond.split("<");
              let attrName = condsplit[0];
              let attrVal = condsplit[1];
              if (operator == "and") {
                shouldinclude = shouldinclude && kvm[attrName] < attrVal;
              } else {
                shouldinclude = shouldinclude || kvm[attrName] < attrVal;
              }
              return;
            }
          });
        }

        if (shouldinclude) {
          if (itm["type"] == "itemlist") {
            let tempItm = JSON.parse(JSON.stringify(itm));
            tempItm._original = itm;
            let tempItmlist = [];

            for (let i = 0; i < itm["items"].length; i++) {
              tempItmlist.push(self.filterHiddenConfigs(itm["items"][i]));
            }

            tempItm["items"] = tempItmlist;
            res.push(tempItm);
          } else {
            res.push(itm);
          }
        }
      });

      return res;
    },
   
  }
};
</script>
<style scoped>
  .pin-btn {
    position: relative;
    left: -10px;
    top: -7px;
  }
</style>