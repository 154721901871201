<template>

<div class="ace-container">
    <!-- ID is used in official documents, it is forbidden to use it here, it is easy to cause problems after packaging later, just use ref or DOM -->
    <div class="ace-editor" ref="ace">
        {{code}}
    </div>
  </div>
</template>
<script>
// import ace from "@/libs/ace/src/ace.js";

import ace from'ace-builds'
import'ace-builds/webpack-resolver'
import'ace-builds/src-noconflict/theme-monokai'
import'ace-builds/src-noconflict/mode-javascript'

export default {
    props:["code"],
    destroyed(){
        this.editor.destroy();
        this.editor.container.remove();
    },
    data(){
        return{
            editor: null,
            currentCode: null,
            // themePath:'ace/theme/monokai',//If webpack-resolver is not imported, the module path will report an error
            modePath:'ace/mode/javascript'//Same as above
        }
    },
    created(){
        this.currentCode = this.code;
    },
    methods: {
        onInput(event){
            this.$emit("update:code", this.editor.getValue())

        }
        
    },
    mounted(){
        this.$nextTick(() => {
            // let editor = ace.edit("editor");
            // editor.setTheme("ace/theme/monokai");
            // editor.getSession().setMode("ace/mode/javascript");
            // this.editor = editor;
            let _this = this;
            this.editor = ace.edit(this.$refs.ace, {
                maxLines: 20,
                minLines: 10,
                fontSize: 14,
                theme: this.themePath,
                mode: this.modePath,
                tabSize: 4
            })
            this.editor.getSession().on('change', function() {
                _this.onInput()
            });
        })
    }
}
</script>
<style scoped>
.ace-editor {
  width: 100%;
  height: 600px;
}
</style>