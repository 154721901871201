<template>
  <div>


    <div
      v-for="(confItem, outerIndex) in configItems"
      :key="confItem.name + 'i' + outerIndex"
     
    >
      <TemplateConfigItemsInputs 
        :inputs="inputs"
        @test-code="$emit('test-code', $event)"
        @set-inputs-outputs="setInputsOutputs"
        @connect-to-inputs="$emit('connect-to-inputs', $event)"
      :class="innerClass" :confItem="confItem" />
      

    </div>
  </div>
</template>

<script>

import TemplateConfigItemsInputs from "./TemplateConfigItemsInputs.vue"
export default {
    name: "TemplateConfigFactory",
    methods: {
     setInputsOutputs(inputs, outputs){
        this.$emit('set-inputs-outputs', inputs, outputs)

     }
    },
  components: {
    TemplateConfigItemsInputs
  },

  props: ["configItems", "innerClass", "inputs"],
};
</script>
